<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <router-link :to="{name:'dashboard'}">Dashboard</router-link>
      </li>
      <li class="breadcrumb-item">
        <router-link :to="{name:'payments'}">Payments</router-link>
      </li>
      <li class="breadcrumb-item"><a href="#">Verify Payments</a></li>
    </ol>
    <h1 class="page-header">Confirm Payments</h1>
    <panel noButton="true" title="Verify Payment" variant="panel-default">
      <div v-html="$error.handle(error)"/>
      <form class="row"
          @submit.prevent="submitForm"
      >
        <div class="form-group col-md-4">
          <label>Transaction Reference</label>
          <input
              v-model="form.TransID"
              v-validate="'required'"
              class="form-control"
              :class="{'is-invalid': errors.has('transId') }"
              name="transId"
              type="text"
              required
          >
          <div :class="{'invalid-feedback': errors.has('transId')}">
            {{ errors.first('transId') }}
          </div>
        </div>
        <div class="form-group col-md-4">
          <label>Amount</label>
          <vue-numeric
              v-model="form.TransAmount"
              v-validate="'required'"
              :class="{'is-invalid': errors.has('transAmount') }"
              class="form-control"
              name="transAmount"
          />
          <div :class="{'invalid-feedback': errors.has('transAmount')}">
            {{ errors.first('transAmount') }}
          </div>
        </div>
        <div class="form-group col-md-4">
          <label for="tenure_duration">Phone</label>
          <input
              v-model="form.MSISDN"
              name="phone"
              class="form-control"
              type="text"
          >
        </div>
        <div class="form-group col-md-4">
          <label>Payer Name</label>
          <input
              v-model="form.FirstName"
              v-validate="'required'"
              :class="{'is-invalid': errors.has('payer') }"
              name="payer"
              class="form-control"
              type="text"
              required
          >
          <div :class="{'invalid-feedback': errors.has('payer')}">
            {{ errors.first('payer') }}
          </div>
        </div>
        <div
            class="form-group col-md-12"
            style="text-align: right;"
        >
          <button
              v-if="confirming"
              type="button"
              class="btn btn-warning btn-loading"
          >
            <i
                class="fa fa-spin fa-spinner"
            /> Verifying payment
          </button>
          <button
              v-else
              type="submit"
              class="btn btn-primary"
          >
            Submit
          </button>
        </div>
      </form>
    </panel>
  </div>
</template>

<script>

import moment from 'moment/src/moment';

export default {
  data() {
    return {
      form: {
        TransactionType: 'Pay Bill',
        TransID: '',
        TransTime: '',
        TransAmount: 0,
        BusinessShortCode: process.env.VUE_APP_MPESA_PAYBILL,
        BillRefNumber: '',
        MSISDN: '',
        FirstName: '',
        MiddleName: '',
        LastName: ''
      },
      error: '',
      confirming: false
    }
  },
  computed: {
    transaction_number() {
      return this.$route.params.account;
    },
    mpesa_paybill() {
      return process.env.VUE_APP_MPESA_PAYBILL;
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$axios.get(`/api/v1/payment/trx/${this.transaction_number}`).then(response => {
        this.form.FirstName = response.data.name;
        this.form.TransAmount = response.data.amount
        this.form.BillRefNumber = response.data.account
        this.form.TransID = response.data.transaction_number
        this.form.MSISDN = response.data.phone
        this.form.TransactionType = response.data.payment_method
        this.form.TransTime = moment(response.data.transaction_date).format('YYYYMMDDHHmmss');
      });
    },
    submitForm(scope) {
      return this.$validator.validateAll(scope).then(result => {
        if (result) {
          this.setConfirmation();
        }
      });
    },
    setConfirmation() {
      const self = this;
      self.confirming = true;
      this.$axios.post('/api/v1/payment/confirmation', this.form).then((resp) => {
        self.confirming = false;
        if (resp.data.ResultCode === 0)
          self.$toastr.s("Payment confirmation successful");
      }).catch(err => {
        this.confirming = false;
        this.error = err.response;
      });
    },
  }
}
</script>