<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <router-link :to="{name:'dashboard'}">Dashboard</router-link>
      </li>
      <li class="breadcrumb-item"><a href="#">Payments</a></li>
    </ol>
    <h1 class="page-header">Payments</h1>
    <panel noButton="true" title="Payments" variant="panel-default">
      <vue-good-table :columns="columns" :lineNumbers="true" :pagination-options="{ enabled: true, position: 'bottom' }" :rows="forTable" :search-options="{ enabled: true }">
        <template slot="table-row" slot-scope="props">
          <div v-if="props.column.field == 'action'">
            <div>
            <router-link v-if="getPayable(props.row.payable_type) === 'Bidbond'" :to="{ name: 'bidbonds.show', params: { id: props.row.payable_id }}">
              {{ getPayable(props.row.payable_type) }}
            </router-link>
            <router-link v-if="getPayable(props.row.payable_type) === 'Company'" :to="{ name: 'companies.show', params: { id: props.row.payable_id }}">
              {{ getPayable(props.row.payable_type) }}
            </router-link>
            <router-link v-if="getPayable(props.row.payable_type) === 'Agent'" :to="{ name: 'agents.show', params: { secret: props.row.payable_id }}">
              {{ getPayable(props.row.payable_type) }}
            </router-link>
            <router-link v-if="props.row.status === 'Pending' && $can.view($store.getters.getAuthUser, 'confirm-payment')"  class="btn btn-sm btn-primary" :to="{name: 'payments.verify',params:{account: props.row.transaction_number}}">
              Confirm
            </router-link>
          </div>
          </div>
          <span v-else>
            {{props.formattedRow[props.column.field]}}
          </span>
        </template>
        <div slot="table-actions">
          <download-excel :data="forExport" :title="'Payment Report'" class="btn btn-primary" name="AllPayments.xls">
            <i class="fa fa-file-excel"></i> Export Excel
          </download-excel>
          <router-link class="btn btn-info ml-2" v-if="$can.view($store.getters.getAuthUser, 'create-payment')" :to="{name:'payments.create'}">Create</router-link>
        </div>
      </vue-good-table>
    </panel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      columns: [
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Phone ",
          field: "phone"
        },
        {
          label: "Account",
          field: "account"
        },
        {
          label: "Amount",
          field: "amount"
        },
        {
          label: "Transaction Number",
          field: "transaction_number"
        },
        {
          label: "Payment Channel",
          field: "payment_method"
        },
        {
          label: "Transaction Date",
          field: "transaction_date"
        },
        {
          label: 'Status',
          field: 'status'
        },
        {
          label: 'Type',
          field: 'action'
        },
      ],
      forTable:[],
      forExport:[],
      payments:[],
    }
  },
  computed: {

  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$axios.get('/api/v1/payments').then(response => {
        if(response.data || response.data.data){
          if(Array.isArray(response.data)){
            this.payments = response.data;
          }
          if(Array.isArray(response.data.data)){
            this.payments = response.data.data;
          }
          this.payments.map(row => {
            this.forTable.push({
              'name': row.name,
              'phone': row.phone,
              'amount': row.amount,
              'account': row.payable_id,
              'transaction_number': row.transaction_number,
              'payment_method': row.payment_method,
              'transaction_date': row.transaction_date  ,
              'status' : (row.confirmed ? "Confirmed" :"Pending" ),
              'payable_type' : this.getPayable(row.payable_type),
              'payable_id': row.payable_id,
            });
            this.forExport.push({
              'Name': row.name,
              'Phone': row.phone,
              'Amount': row.amount,
              'Account': row.payable_id,
              'Transaction Number': row.transaction_number,
              'Payment Method': row.payment_method,
              'Transaction Date': row.transaction_date  ,
              'Status' : (row.confirmed ? "Confirmed" :"Pending" ),
              'Payable Type' : this.getPayable(row.payable_type)
            });
          });
        }
        this.loading = false;
      });
    },
    getPayable(payable_type) {
      return payable_type.replace("App\\", "");
    },
  }
}
</script>